import React from 'react'
import { graphql } from 'gatsby'

import Link from 'components/Link'
import Button from 'components/Button'
import { Container, Row, Column } from 'components/Grid'

import { StyledSection, StyledTitle, StyledDescription, StyledLinks } from './styles'

export default function SimpleHero({ id, title, description, links }) {
   return (
      <StyledSection>
         <Container>
            <Row>
               <Column style={{ position: 'relative', boxShadow: 'rgb(0 0 0 / 6%) 0px 30px 30px -30px' }}>
                  <StyledTitle>{title}</StyledTitle>
                  <StyledDescription>{description?.childMarkdownRemark?.content}</StyledDescription>
                  {Array.isArray(links) && (
                     <StyledLinks isCentered={links.length <= 1}>
                        {links.map((link) => (
                           <Link key={link.id} url={link.url} type={link.type}>
                              <Button>{link.title}</Button>
                           </Link>
                        ))}
                     </StyledLinks>
                  )}
               </Column>
            </Row>
         </Container>
      </StyledSection>
   )
}

export const query = graphql`
   fragment SimpleHero on ContentfulComponentSimpleHero {
      id
      title
      description {
         childMarkdownRemark {
            content: rawMarkdownBody
         }
      }
      links {
         id
         type
         url
         title
      }
   }
`
