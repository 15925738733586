import styled from 'styled-components'
import media from 'utils/media'

export const StyledSection = styled.section`
   padding-top: 30px;
   text-align: center;

   ${media.greaterThan('md')`
      padding-top: 80px;
   `};

   ${media.greaterThan('xl')`
      padding-top: 86px;
   `};
`

export const StyledTitle = styled.h1`
   margin-bottom: 24px;
   color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.mccBlue)};
   font-size: 40px;
   line-height: 52px;
   letter-spacing: 1.28px;

   ${media.greaterThan('md')`
      font-size: 68px;
      line-height: 1;
   `};

   ${media.greaterThan('lg')`
      font-size: 96px;
      line-height: 126px;
   `};

   ${media.greaterThan('xl')`
      font-size: 128px;
      line-height: 168px;
   `};
`

export const StyledDescription = styled.p`
   margin-bottom: 40px;
   font-family: ${({ theme }) => theme.fonts.heldane_text};
   font-size: 16px;
   line-height: 22px;
   letter-spacing: 0.58px;
   color: ${({ theme }) => (theme.darkMode ? theme.colors.textColor : theme.colors.mccBlue)};

   ${media.greaterThan('md')`
      font-size: 24px;
      line-height: 32px;
   `};

   ${media.greaterThan('lg')`
      font-size: 32px;
      line-height: 43px;
      max-width: 800px;
      margin: 0 auto 40px;
   `};

   ${media.greaterThan('xl')`
      max-width: 940px;
   `};
`

export const StyledLinks = styled.div`
   width: 100%;
   margin: 0 auto 71px;

   ${media.greaterThan('sm')`
      width: 400px;
      display: flex;
      justify-content: ${({ isCentered }) => (isCentered ? 'center' : 'space-between')};
   `};

   ${media.greaterThan('md')`
      width: 450px;
   `};

   a + a {
      display: block;
      margin-top: 24px;

      ${media.greaterThan('sm')`
         margin-top: 0px;
      `};
   }
`
