import React from 'react'
import { graphql } from 'gatsby'

import { LangContext } from 'context'
import BridgeComponent from 'utils/connectBlogPageBody'

import Layout from 'components/Layout'

export default function BlogPageTemplate({ data, pageContext, location }) {
   const { lang, category } = pageContext || {}
   const { page, posts, writingPosts, podcastPosts, videoPosts, featuredPosts, categories, header, footer } = data || {}

   function renderPageSections(sections) {
      if (Array.isArray(sections)) {
         return sections.map((section) => {
            if (section.__typename === 'ContentfulComponentPostsList') {
               return (
                  <BridgeComponent
                     key={section.id}
                     {...section}
                     posts={Array.isArray(posts?.edges) ? posts.edges : []}
                     writingPosts={Array.isArray(writingPosts?.edges) ? writingPosts.edges : []}
                     podcastPosts={Array.isArray(podcastPosts?.edges) ? podcastPosts.edges : []}
                     videoPosts={Array.isArray(videoPosts?.edges) ? videoPosts.edges : []}
                     featuredPosts={Array.isArray(featuredPosts?.edges) ? featuredPosts.edges : []}
                     categories={Array.isArray(categories?.edges) ? categories.edges : []}
                     path={location.pathname}
                     pagination={{ ...posts?.pageInfo, isBlog: !category }}
                     category={category}
                  />
               )
            }
            return <BridgeComponent key={section.id} {...section} />
         })
      }
      return <h2>This Page dont have any Components in Contentfull</h2>
   }

   return (
      <LangContext.Provider value={{ lang, path: location.pathname }}>
         <Layout
            lang={lang}
            pageTitle={page?.title}
            pageDescription={page?.description}
            location={location}
            header={header}
            footer={footer}
            pathname={location.pathname}
         >
            {renderPageSections(page?.body)}
         </Layout>
      </LangContext.Provider>
   )
}

export const blogPageQuery = graphql`
   query blogPageQuery($lang: String!, $id: String!, $skip: Int!) {
      page: contentfulPage(id: { eq: $id }) {
         title
         description
         body {
            __typename
            ...SimpleHero
            ...PostsList
         }
      }
      posts: allContentfulPost(
         limit: 10
         filter: { node_locale: { eq: $lang } }
         sort: { order: DESC, fields: [postDate] }
         skip: $skip
      ) {
         edges {
            node {
               node_locale
               id
               slug
               postTitle
               postExcerpt {
                  text: postExcerpt
               }
               postDate(formatString: "MMMM D, YYYY")
               timeRead
               postAuthors {
                  id
                  name
                  profilePhoto {
                     gatsbyImage(height: 100, width: 100)
                  }
                  socialUrl
               }
               postCategories {
                  name
                  categoryName
                  categoryLabel
                  node_locale
               }
               cover {
                  title
                  coverType
                  cover {
                     id
                     gatsbyImageData
                  }
                  coverVideo
               }
            }
         }
         pageInfo {
            currentPage
            totalCount
            perPage
            pageCount
            itemCount
            hasNextPage
            hasPreviousPage
         }
      }
      writingPosts: allContentfulPost(
         filter: { postCategories: { elemMatch: { categoryName: { eq: "writing" }, node_locale: { eq: $lang } } } }
         sort: { order: DESC, fields: [postDate] }
         limit: 5
      ) {
         edges {
            node {
               id
               postTitle
               slug
               postDate(formatString: "MMMM D, YYYY")
            }
         }
      }
      videoPosts: allContentfulPost(
         filter: { postCategories: { elemMatch: { categoryName: { eq: "videos" }, node_locale: { eq: $lang } } } }
         sort: { order: DESC, fields: [postDate] }
         limit: 5
      ) {
         edges {
            node {
               id
               postTitle
               slug
               postDate(formatString: "MMMM D, YYYY")
            }
         }
      }
      podcastPosts: allContentfulPost(
         filter: { postCategories: { elemMatch: { categoryName: { eq: "podcasts" }, node_locale: { eq: $lang } } } }
         sort: { order: DESC, fields: [postDate] }
         limit: 5
      ) {
         edges {
            node {
               id
               postTitle
               slug
               postDate(formatString: "MMMM D, YYYY")
            }
         }
      }
      featuredPosts: allContentfulPost(
         filter: { postCategories: { elemMatch: { categoryName: { eq: "featured" }, node_locale: { eq: $lang } } } }
         sort: { order: DESC, fields: [postDate] }
         limit: 5
      ) {
         edges {
            node {
               id
               postTitle
               slug
               postDate(formatString: "MMMM D, YYYY")
            }
         }
      }
      categories: allContentfulCategory(filter: { node_locale: { eq: $lang } }) {
         edges {
            node {
               categoryName
               categoryLabel
            }
         }
      }
      header: contentfulHeader(node_locale: { eq: $lang }) {
         searchPlaceholder
         headerLabel
         newsletterText {
            content: newsletterText
         }
         desktopNav {
            __typename
            ... on ContentfulLink {
               id
               name
               type
               url
               title
            }
         }
         mobileNav {
            id
            title
            type
            url
         }
         smallNav {
            id
            title
            type
            url
         }
      }
      footer: contentfulFooter(node_locale: { eq: $lang }) {
         menu {
            id
            title
            type
            url
         }
         smallMenu {
            id
            title
            type
            url
         }
         textContent {
            markdown: textContent
         }
         copyText {
            markdown: copyText
         }
         copyTitle
      }
   }
`
